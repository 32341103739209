import React, {FC, useMemo, useState, ChangeEvent} from 'react'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import clsx from 'clsx'

type Props = {
  id?: string
  name?: string
  label?: string
  labelClassName?: string
  className?: string
  required?: boolean
  intType?: boolean
  title?: string
  orientation?: 'left' | 'right'
  onChange?: (value: boolean) => void
  disabled?: boolean
  readOnly?: boolean
}

const Switch: FC<Props> = ({
  name,
  label = '',
  labelClassName = '',
  className = '',
  intType = false,
  required = false,
  title,
  orientation = 'left',
  onChange,
  ...restProps
}) => {
  const intl = useIntl()
  const [isChecked, setIsChecked] = useState(false)

  const id = restProps?.id || `${name || ''}-${Math.random()}`
  const labelHasTranslation = label ? !!intl.messages[label] : false
  const titleHasTranslation = title ? !!intl.messages[title] : false

  const renderTooltip = useMemo(() => {
    return (
      <Tooltip id={`tooltip-${id}`}>
        {titleHasTranslation ? intl.formatMessage({id: title}) : title}
      </Tooltip>
    )
  }, [title, id, intl, titleHasTranslation])

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newChecked = e.target.checked
    setIsChecked(newChecked)
    if (onChange) {
      if (intType) {
        onChange(!newChecked) // Change false to true and vice versa
      } else {
        onChange(newChecked)
      }
    }
  }

  const labelElement = useMemo(() => {
    return (
      <div>
        {label && (
          <label
            htmlFor={id}
            className={clsx('col-form-label font-weight-bold text-right', labelClassName, {
              'me-1': orientation === 'right',
              'ms-1': orientation !== 'right',
              required,
            })}
          >
            {labelHasTranslation ? intl.formatMessage({id: label}) : label}
            {title && (
              <OverlayTrigger placement='top' overlay={renderTooltip}>
                <span className='badge pe-auto'>
                  <i className='bi bi-info-circle-fill'></i>
                </span>
              </OverlayTrigger>
            )}
          </label>
        )}
      </div>
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [label, title, required, id, renderTooltip, intl, labelHasTranslation])

  return (
    <div
      className={`form-check form-switch form-switch-sm form-check-custom form-check-solid ${className}`}
    >
      {orientation === 'left' ? (
        <>
          {labelElement}
          <input
            id={id}
            name={name}
            type='checkbox'
            className='form-check-input col-lg-9 col-xl-6'
            role='switch'
            checked={isChecked}
            onChange={handleChange}
            {...restProps}
          />
        </>
      ) : (
        <>
          <input
            id={id}
            name={name}
            type='checkbox'
            className='form-check-input col-lg-9 col-xl-6'
            role='switch'
            checked={isChecked}
            onChange={handleChange}
            {...restProps}
          />
          {labelElement}
        </>
      )}
    </div>
  )
}

export default Switch
