export const GET_SUBJECT_GROUPS = 'GET_SUBJECT_GROUPS'
export const GET_SUBJECT_GROUP = 'GET_SUBJECT_GROUP'
export const CREATE_SUBJECT_GROUP = 'CREATE_SUBJECT_GROUP'
export const UPDATE_SUBJECT_GROUP = 'UPDATE_SUBJECT_GROUP'
export const DELETE_SUBJECT_GROUP = 'DELETE_SUBJECT_GROUP'

export const GET_ADMISSION_SUBJECTS = 'GET_ADMISSION_SUBJECTS'
export const GET_ADMISSION_SUBJECT = 'GET_ADMISSION_SUBJECT'
export const CREATE_ADMISSION_SUBJECT = 'CREATE_ADMISSION_SUBJECT'
export const UPDATE_ADMISSION_SUBJECT = 'UPDATE_ADMISSION_SUBJECT'
export const DELETE_ADMISSION_SUBJECT = 'DELETE_ADMISSION_SUBJECT'

export const GET_ADMISSION_QUESTIONS = 'GET_ADMISSION_QUESTIONS'
export const GET_ADMISSION_QUESTION = 'GET_ADMISSION_QUESTION'
export const CREATE_ADMISSION_QUESTION = 'CREATE_ADMISSION_QUESTION'
export const UPDATE_ADMISSION_QUESTION = 'UPDATE_ADMISSION_QUESTION'
export const DELETE_ADMISSION_QUESTION = 'DELETE_ADMISSION_QUESTION'

export const GET_SUBJECT_EXAMS = 'GET_SUBJECT_EXAMS'
export const GET_SUBJECT_EXAM = 'GET_SUBJECT_EXAM'
export const CREATE_SUBJECT_EXAM = 'CREATE_SUBJECT_EXAM'
export const UPDATE_SUBJECT_EXAM = 'UPDATE_SUBJECT_EXAM'
export const DELETE_SUBJECT_EXAM = 'DELETE_SUBJECT_EXAM'

export const GET_SUBJECT_GRADES = 'GET_SUBJECT_GRADES'
export const GET_SUBJECT_GRADE = 'GET_SUBJECT_GRADE'
export const CREATE_SUBJECT_GRADE = 'CREATE_SUBJECT_GRADE'
export const UPDATE_SUBJECT_GRADE = 'UPDATE_SUBJECT_GRADE'
export const DELETE_SUBJECT_GRADE = 'DELETE_SUBJECT_GRADE'

export const GET_PREVIOUS_SUBJECTS = 'GET_PREVIOUS_SUBJECTS'
export const GET_PREVIOUS_SUBJECT = 'GET_PREVIOUS_SUBJECT'
export const CREATE_PREVIOUS_SUBJECT = 'CREATE_PREVIOUS_SUBJECT'
export const UPDATE_PREVIOUS_SUBJECT = 'UPDATE_PREVIOUS_SUBJECT'
export const DELETE_PREVIOUS_SUBJECT = 'DELETE_PREVIOUS_SUBJECT'

export const GET_SUBJECT_COMBINATIONS = 'GET_SUBJECT_COMBINATIONS'
export const GET_SUBJECT_COMBINATION = 'GET_SUBJECT_COMBINATION'
export const CREATE_SUBJECT_COMBINATION = 'CREATE_SUBJECT_COMBINATION'
export const UPDATE_SUBJECT_COMBINATION = 'UPDATE_SUBJECT_COMBINATION'
export const DELETE_SUBJECT_COMBINATION = 'DELETE_SUBJECT_COMBINATION'

export const GET_SUBJECT_COMPONENTS = 'GET_SUBJECT_COMPONENTS'
export const GET_SUBJECT_COMPONENT = 'GET_SUBJECT_COMPONENT'
export const CREATE_SUBJECT_COMPONENT = 'CREATE_SUBJECT_COMPONENT'
export const UPDATE_SUBJECT_COMPONENT = 'UPDATE_SUBJECT_COMPONENT'
export const DELETE_SUBJECT_COMPONENT = 'DELETE_SUBJECT_COMPONENT'

export const GET_PREVIOUS_CLASSES = 'GET_PREVIOUS_CLASSES'
export const GET_PREVIOUS_CLASS = 'GET_PREVIOUS_CLASS'
export const CREATE_PREVIOUS_CLASS = 'CREATE_PREVIOUS_CLASS'
export const UPDATE_PREVIOUS_CLASS = 'UPDATE_PREVIOUS_CLASS'
export const DELETE_PREVIOUS_CLASS = 'DELETE_PREVIOUS_CLASS'

export const GET_PREVIOUS_SCHOOLS = 'GET_PREVIOUS_SCHOOLS'
export const GET_PREVIOUS_SCHOOL = 'GET_PREVIOUS_SCHOOL'
export const CREATE_PREVIOUS_SCHOOL = 'CREATE_PREVIOUS_SCHOOL'
export const UPDATE_PREVIOUS_SCHOOL = 'UPDATE_PREVIOUS_SCHOOL'
export const DELETE_PREVIOUS_SCHOOL = 'DELETE_PREVIOUS_SCHOOL'

export const GET_ADMISSION_STAGES = 'GET_ADMISSION_STAGES'
export const GET_ADMISSION_STAGE = 'GET_ADMISSION_STAGE'
export const UPDATE_ADMISSION_STAGE = 'UPDATE_ADMISSION_STAGE'
