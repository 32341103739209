import axios from 'axios'
import clsx from 'clsx'
import {getRandomInt, getTenantDomain} from 'common/HelperFunctions'
import {FC, useMemo} from 'react'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import ReactQuill, {ReactQuillProps} from 'react-quill'
import {toast} from 'react-toastify'
import {confirmFileUpload, getSignedUploadUrl} from 'setup/redux/Actions/Site/site'
import store from 'setup/redux/Store'

type Props = {
  label?: string
  labelClassName?: string
  required?: boolean
  showError?: boolean
  title?: string
  acceptImage?: boolean
} & ReactQuillProps

const modules = {
  toolbar: [
    [{header: [1, 2, 3, 4, 5, 6, false]}],
    [{font: []}],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{color: []}, {background: []}],
    [{list: 'ordered'}, {list: 'bullet'}],
    [{indent: '-1'}, {indent: '+1'}],
    ['link', 'video', 'image', 'formula'],
    ['clean'],
  ],
  imageUploader: {
    upload: (file: File) => {
      return new Promise((resolve, reject) => {
        const domain = getTenantDomain()
        const tenant = domain.replaceAll('.', '_')
        const IMAGE_UPLOAD_PATH = `uploads/${tenant}/images`

        const lastIndex = file.name.lastIndexOf('.')
        const fileName = file.name.slice(0, lastIndex)
        const body = {
          path: IMAGE_UPLOAD_PATH,
          fileName,
          contentType: file.type,
        }

        const onFailure = (err: any) => {
          const message =
            err?.response?.data?.message ?? err?.message ?? 'Error in uploading the message'
          toast.error(message)
          reject(err)
        }

        store.dispatch(
          getSignedUploadUrl(
            body,
            (res: any) => {
              const {fileUrl, uploadURL} = res.result

              file.arrayBuffer().then((buffer: any) => {
                toast.info(`Uploading ${file.name}`)
                axios
                  .put(uploadURL, buffer)
                  .then((r) => {
                    const body = {
                      path: IMAGE_UPLOAD_PATH,
                      original_filename: file.name,
                      file_name: fileUrl.split('/').slice(-1).pop(),
                      url: fileUrl,
                      size: file.size,
                      contentType: file.type,
                    }

                    store.dispatch(
                      confirmFileUpload(
                        body,
                        (res: any) => {
                          toast.success(`${file.name} uploaded successfully`)
                          resolve(fileUrl)
                        },
                        onFailure
                      )
                    )
                  })
                  .catch(onFailure)
              })
            },
            onFailure
          )
        )
      })
    },
  },
}

const id = `text-editor-${getRandomInt(0, 100000)}`

export const TextEditor: FC<Props> = ({
  label,
  labelClassName,
  required = false,
  title,
  showError = true,
  className,
  acceptImage = true,
  onChange,
  ...remainingProps
}) => {
  const intl = useIntl()

  const labelHasTranslation = label ? !!intl.messages[label] : false
  const titleHasTranslation = title ? !!intl.messages[title] : false

  const renderTooltip = useMemo(() => {
    return (
      <Tooltip id={`tooltip-${id}`}>
        {titleHasTranslation ? intl.formatMessage({id: title}) : title}
      </Tooltip>
    )
  }, [title, intl, titleHasTranslation])

  if (!acceptImage) {
    modules.toolbar[6].splice(2, 1)
  }

  return (
    <>
      {label && (
        <label
          className={clsx('form-label', labelClassName, {
            required,
          })}
          htmlFor={id}
        >
          {labelHasTranslation ? intl.formatMessage({id: label}) : label}
          {title && (
            <OverlayTrigger placement='right' overlay={renderTooltip}>
              <div className='badge pe-auto'>
                <i className='bi bi-info-circle-fill'></i>
              </div>
            </OverlayTrigger>
          )}
        </label>
      )}
      <div id={`${id}-container`}>
        {
          <ReactQuill
            id={id}
            bounds={`#${id}-container`}
            className={clsx('border rounded', className)}
            theme='snow'
            modules={modules}
            onChange={(value, delta, source, editor) => {
              let v = undefined
              if (value === '<p><br></p>') {
                v = ''
              } else {
                v = value
              }
              if (typeof onChange === 'function') onChange(v, delta, source, editor)
            }}
            {...remainingProps}
          />
        }
      </div>
    </>
  )
}
