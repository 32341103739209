import {
  GET_DOCUMENT,
  GET_DOCUMENTS,
  DELETE_DOCUMENT,
  GET_DOC_SIGNED_URL,
  CONFIRM_DOC_UPLOAD,
} from './ActionTypes/ApiActionTypes'

export const getDocuments = (data, onSuccess, onFailure) => {
  return {
    type: GET_DOCUMENTS,
    payload: {
      apiUrl: '/documents',
      getString: '',
      useBaseURL: true,
      requestType: 'POST',
      header: 'application/json',
      body: data,
      reduxActionType: '',
      onSuccess,
      onFailure,
    },
  }
}

export const getDocument = (id, onSuccess, onFailure) => {
  return {
    type: GET_DOCUMENT,
    payload: {
      apiUrl: `documents/${id}`,
      getString: '',
      useBaseURL: true,
      requestType: 'GET',
      header: 'application/json',
      reduxActionType: '',
      onSuccess,
      onFailure,
    },
  }
}

export const deleteDocument = (id, onSuccess, onFailure) => {
  return {
    type: DELETE_DOCUMENT,
    payload: {
      apiUrl: `documents/${id}`,
      getString: '',
      useBaseURL: true,
      requestType: 'DELETE',
      header: 'application/json',
      reduxActionType: '',
      onSuccess,
      onFailure,
    },
  }
}

export const getSignedUploadUrl = (body, onSuccess, onFailure) => {
  return {
    type: GET_DOC_SIGNED_URL,
    payload: {
      apiUrl: `documents/signed-url`,
      getString: '',
      useBaseURL: true,
      body,
      requestType: 'POST',
      header: 'application/json',
      reduxActionType: '',
      onSuccess,
      onFailure,
    },
  }
}

export const confirmFileUpload = (body, onSuccess, onFailure) => {
  return {
    type: CONFIRM_DOC_UPLOAD,
    payload: {
      onSuccess,
      onFailure,
      apiUrl: 'documents/confirm-upload',
      getString: '',
      useBaseURL: true,
      requestType: 'POST',
      header: 'application/json',
      reduxActionType: '',
      body,
    },
  }
}
