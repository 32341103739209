import {takeEvery} from 'redux-saga/effects'
import {
  GET_ACCOUNT_TYPE,
  GET_ACCOUNT_TYPES,
  DELETE_ACCOUNT_TYPE,
  ADD_ACCOUNT_TYPE,
  DELETE_MANY_ACCOUNT_TYPE,
  UPDATE_ACCOUNT_TYPE,
} from 'setup/redux/Actions/AccountType/ActionTypes/ApiActionTypes'
import fetchData from '../../SagaHelper'

function* dataSaga() {
  yield takeEvery(GET_ACCOUNT_TYPES, fetchData)
  yield takeEvery(GET_ACCOUNT_TYPE, fetchData)
  yield takeEvery(DELETE_ACCOUNT_TYPE, fetchData)
  yield takeEvery(ADD_ACCOUNT_TYPE, fetchData)
  yield takeEvery(DELETE_MANY_ACCOUNT_TYPE, fetchData)
  yield takeEvery(UPDATE_ACCOUNT_TYPE, fetchData)
}

export default dataSaga
