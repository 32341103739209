import {ButtonHTMLAttributes, FC} from 'react'
import Spinner from './Spinner'
import clsx from 'clsx'

type Color = 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' | 'dark'
export type ButtonColor =
  | Color
  | 'light'
  | 'dark'
  | `outline-${Color}`
  | 'outline-light'
  | 'outline-dark'
  | `light-${Color}`

type Props = {
  loading?: boolean
  size?: 'sm' | 'lg'
  color?: ButtonColor
  outline?: boolean
  className?: string
} & ButtonHTMLAttributes<HTMLButtonElement>

const Button: FC<Props> = ({
  children,
  loading = false,
  size,
  color = 'primary',
  className,
  ...remainingProps
}) => {
  return (
    <button
      className={clsx(
        'btn',
        {[`btn-${color}`]: !!color},
        {[`btn-${size}`]: !!size},
        'd-inline-flex align-items-center justify-content-center',
        className
      )}
      {...remainingProps}
    >
      {loading && <Spinner size='sm' className='me-1' />}
      {children}
    </button>
  )
}

export default Button
