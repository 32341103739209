import {takeEvery} from 'redux-saga/effects'
import {
  GET_INVENTORY_SETTINGS,
  UPDATE_INVENTORY_SETTINGS,
} from 'setup/redux/Actions/InventorySettings/ActionTypes/ApiActionType'
import fetchData from '../../SagaHelper'

function* dataSaga() {
  yield takeEvery(GET_INVENTORY_SETTINGS, fetchData)
  yield takeEvery(UPDATE_INVENTORY_SETTINGS, fetchData)
}

export default dataSaga
