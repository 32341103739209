import {KTCard, KTCardBody, KTSVG} from '_metronic/helpers'
import Spinner from 'common/components/Spinner'
import {DEVICE_TYPES} from 'common/constants'
import {getDeviceTypeIcon, getDeviceTypeLabel} from 'common/helpers'
import {FC, useMemo} from 'react'
import {useIntl} from 'react-intl'

type Props = {
  total: number
  stats: {
    web: number
    android: number
    ios: number
  }
  loading?: boolean
} & React.HTMLAttributes<HTMLDivElement>

const color = 'success'

const Platform: FC<Props> = ({stats, loading, ...props}) => {
  const intl = useIntl()
  const android = useMemo(() => stats.android, [stats.android])
  const ios = useMemo(() => stats.ios, [stats.ios])
  const web = useMemo(() => stats.web, [stats.web])
  const total = useMemo(() => android + ios + web, [android, ios, web])

  if (loading) {
    return (
      <KTCard>
        <KTCardBody>
          <div className='center p-5'>
            <Spinner color='primary' />
          </div>
        </KTCardBody>
      </KTCard>
    )
  }

  return (
    <div className={`card card-xl-stretch mb-xl-8`} {...props}>
      <div className='card-body p-0'>
        <div className={`px-9 pt-7 card-rounded h-275px w-100 bg-${color}`}>
          <div className='d-flex flex-stack'>
            <h3 className='m-0 text-white fw-bolder fs-3'>Device Stats</h3>
          </div>

          <div className='d-flex text-center flex-column text-white pt-8'>
            <span className='fw-bold fs-5'>
              Currently logged in devices, total = {total.toLocaleString()}
            </span>
            {/* <span className='fw-bolder fs-2x pt-1'>{total.toLocaleString()}</span> */}
          </div>
        </div>

        <div
          className='shadow-xs card-rounded mx-9 mb-9 px-6 py-9 position-relative z-index-1 bg-body'
          style={{marginTop: '-100px'}}
        >
          {/* android */}
          <div className='d-flex align-items-center mb-6'>
            <div className='symbol symbol-45px w-40px me-5'>
              <span className='symbol-label bg-lighten'>
                <KTSVG path={getDeviceTypeIcon(DEVICE_TYPES.ANDROID)} className='svg-icon-1' />
              </span>
            </div>

            <div className='d-flex align-items-center flex-wrap w-100'>
              <div className='mb-1 pe-3 flex-grow-1'>
                <span className='fs-3 text-gray-800 text-hover-primary fw-bolder'>
                  {intl.$t({id: getDeviceTypeLabel(DEVICE_TYPES.ANDROID)})}
                </span>
              </div>

              <div className='d-flex align-items-center'>
                <div className='fw-bolder fs-5 text-gray-800 pe-1'>{android.toLocaleString()}</div>
              </div>
            </div>
          </div>

          {/* ios */}
          <div className='d-flex align-items-center mb-6'>
            <div className='symbol symbol-45px w-40px me-5'>
              <span className='symbol-label bg-lighten'>
                <KTSVG path={getDeviceTypeIcon(DEVICE_TYPES.IOS)} className='svg-icon-1' />
              </span>
            </div>

            <div className='d-flex align-items-center flex-wrap w-100'>
              <div className='mb-1 pe-3 flex-grow-1'>
                <span className='fs-3 text-gray-800 text-hover-primary fw-bolder'>
                  {intl.$t({id: getDeviceTypeLabel(DEVICE_TYPES.IOS)})}
                </span>
              </div>

              <div className='d-flex align-items-center'>
                <div className='fw-bolder fs-5 text-gray-800 pe-1'>{ios.toLocaleString()}</div>
              </div>
            </div>
          </div>

          {/* web */}
          <div className='d-flex align-items-center mb-6'>
            <div className='symbol symbol-45px w-40px me-5'>
              <span className='symbol-label bg-lighten'>
                <KTSVG path={getDeviceTypeIcon(DEVICE_TYPES.WEB)} className='svg-icon-1' />
              </span>
            </div>

            <div className='d-flex align-items-center flex-wrap w-100'>
              <div className='mb-1 pe-3 flex-grow-1'>
                <span className='fs-3 text-gray-800 text-hover-primary fw-bolder'>
                  {intl.$t({id: getDeviceTypeLabel(DEVICE_TYPES.WEB)})}
                </span>
              </div>

              <div className='d-flex align-items-center'>
                <div className='fw-bolder fs-5 text-gray-800 pe-1'>{web.toLocaleString()}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Platform
