import {USER_IMPERSONATE} from 'setup/redux/Actions/User/ActionTypes/ApiActionTypes'
import {
  REDUX_IMPERSONATE,
  REDUX_REVERT_TO_ORIGINAL_USER,
} from 'setup/redux/Actions/User/ActionTypes/ReduxActionTypes'
import store from 'setup/redux/Store'

const impersonate = (data: {
  accessToken: string
  user: any
  userType: string
  appSettings: any
  permissions: any[]
}) => {
  store.dispatch({
    type: REDUX_IMPERSONATE,
    payload: {
      accessToken: data.accessToken,
      user: data.user,
      userType: data.userType,
      appSettings: data.appSettings,
      permissions: data.permissions,
    },
  })
}

export const revertToOriginalUser = () => {
  store.dispatch({type: REDUX_REVERT_TO_ORIGINAL_USER})
  setTimeout(() => {
    window.location.href = '/dashboard'
  }, 150)
}

export const impersonateUser = ({
  userId,
  onSuccess,
  onFailure,
}: {
  userId: number
  onSuccess?: (res: any) => void
  onFailure?: (err: any) => void
}) => {
  const handleSuccess = (res: any) => {
    impersonate(res.result)
    if (onSuccess) onSuccess(res)
    setTimeout(() => {
      window.location.href = '/dashboard'
    }, 150)
  }

  store.dispatch({
    type: USER_IMPERSONATE,
    payload: {
      onSuccess: handleSuccess,
      onFailure,
      apiUrl: 'user/impersonate',
      getString: '',
      body: {userId},
      useBaseURL: true,
      requestType: 'POST',
      header: 'application/json',
      reduxActionType: '',
    },
  })
}
