import {
  GET_HOMEWORKS,
  GET_HOMEWORK_DETAIL,
  GET_HOMEWORKS_MESSAGES,
  SEND_HOMEWORK_MESSAGE,
  GET_SIGNED_UPLOAD_URL,
  CONFIRM_FILE_UPLOAD,
  SUBMIT_HOMEWORK,
  DELETE_HOMEWORK_FILE,
  GET_ALL_HOMEWORK,
  GET_HOMEWORK,
  ADD_HOMEWORK,
  GET_HOMEWORK_RESOURCES,
  ADD_HOMEWORK_RESOURCE,
  DELETE_HOMEWORK,
  DELETE_HOMEWORK_RESOURCE,
  UPDATE_HOMEWORK,
  DELETE_MANY_HOMEWORK,
  ADD_MANY_HOMEWORK,
  GET_HOMEWORK_RESULTS,
  GET_HOMEWORK_REPORTS,
  UPDATE_HOMEWORK_RESULT,
  VIEW_HOMEWORK_SUBMISSION,
  GET_HOMEWORK_FEEDBACK,
  ADD_HOMEWORK_FEEDBACK,
  DELETE_HOMEWORK_FEEDBACK,
  ASSIGN_MARKS_TO_ALL_HOMEWORKS,
  ASSIGN_TOP_HOMEWORKS,
  GRADEWISE_HOMEWORK_LIMIT,
  SEND_HOMEWORK_NOTIFICATION,
  SEND_HOMEWORK_SMS,
  TEACHER_CREATE_PERMISSION_HW,
} from '../../Actions/Homework/ActionTypes/ApiActionTypes'
import fetchData from '../SagaHelper'
import {takeEvery} from 'redux-saga/effects'

function* dataSaga() {
  yield takeEvery(GET_HOMEWORKS, fetchData)
  yield takeEvery(GET_HOMEWORK_DETAIL, fetchData)
  yield takeEvery(GET_HOMEWORKS_MESSAGES, fetchData)
  yield takeEvery(SEND_HOMEWORK_MESSAGE, fetchData)
  yield takeEvery(GET_SIGNED_UPLOAD_URL, fetchData)
  yield takeEvery(CONFIRM_FILE_UPLOAD, fetchData)
  yield takeEvery(DELETE_HOMEWORK_FILE, fetchData)
  yield takeEvery(SUBMIT_HOMEWORK, fetchData)
  yield takeEvery(GET_ALL_HOMEWORK, fetchData)
  yield takeEvery(GET_HOMEWORK, fetchData)
  yield takeEvery(ADD_HOMEWORK, fetchData)
  yield takeEvery(GET_HOMEWORK_RESOURCES, fetchData)
  yield takeEvery(ADD_HOMEWORK_RESOURCE, fetchData)
  yield takeEvery(DELETE_HOMEWORK, fetchData)
  yield takeEvery(DELETE_HOMEWORK_RESOURCE, fetchData)
  yield takeEvery(UPDATE_HOMEWORK, fetchData)
  yield takeEvery(DELETE_MANY_HOMEWORK, fetchData)
  yield takeEvery(ADD_MANY_HOMEWORK, fetchData)
  yield takeEvery(GET_HOMEWORK_RESULTS, fetchData)
  yield takeEvery(GET_HOMEWORK_REPORTS, fetchData)
  yield takeEvery(UPDATE_HOMEWORK_RESULT, fetchData)
  yield takeEvery(VIEW_HOMEWORK_SUBMISSION, fetchData)
  yield takeEvery(GET_HOMEWORK_FEEDBACK, fetchData)
  yield takeEvery(ADD_HOMEWORK_FEEDBACK, fetchData)
  yield takeEvery(DELETE_HOMEWORK_FEEDBACK, fetchData)
  yield takeEvery(ASSIGN_MARKS_TO_ALL_HOMEWORKS, fetchData)
  yield takeEvery(ASSIGN_TOP_HOMEWORKS, fetchData)
  yield takeEvery(GRADEWISE_HOMEWORK_LIMIT, fetchData)
  yield takeEvery(SEND_HOMEWORK_NOTIFICATION, fetchData)
  yield takeEvery(SEND_HOMEWORK_SMS, fetchData)
  yield takeEvery(TEACHER_CREATE_PERMISSION_HW, fetchData)
}

export default dataSaga
