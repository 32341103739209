import React, {FC, useCallback, useEffect, useMemo, useState} from 'react'
import {useIntl} from 'react-intl'
import ReactSelect, {
  ActionMeta,
  GroupBase,
  OptionsOrGroups,
  ValueContainerProps,
  components,
} from 'react-select'
import _ from 'lodash'
import {CUSTOM_SELECT_ALL_VALUE} from 'common/constants'
import clsx from 'clsx'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'

interface Props extends React.ComponentProps<typeof ReactSelect> {
  value?: any
  label?: string
  className?: string
  required?: boolean
  title?: string
  optionLabel?: string
  optionValue?: string
  helperText?: string
  placeholder?: string
  selectAllOrOne?: boolean
  onChange?: (value: any) => void
  returnFullOption?: boolean
}

const Option = (props: any) => (
  <components.Option {...props}>
    {props.value === CUSTOM_SELECT_ALL_VALUE ? (
      <label className='form-label'>{props.label}</label>
    ) : (
      <div className='form-check form-check-sm form-check-custom form-check-solid'>
        <input
          name={props.value || props.data?.value}
          className='form-check-input'
          type='checkbox'
          checked={props.isSelected}
          readOnly
        />
        <label className='form-check-label'>{props.label}</label>
      </div>
    )}
  </components.Option>
)

const ValueContainer: FC<ValueContainerProps> = (props) => {
  const {children, isMulti, getValue} = props

  if (!isMulti) {
    return <components.ValueContainer {...props} />
  }

  const values = getValue()
  let valueLabel = ''

  if (values.length > 0) {
    let label = props.selectProps.getOptionLabel(values[0])
    if (label.length > 30) {
      label = label.substring(0, 30) + '...'
    }
    valueLabel += label
  }
  if (values.length > 1) valueLabel += ` & ${values.length - 1} more`

  // Keep standard placeholder and input from react-select
  const childrenToRender = React.Children.toArray(children).filter((child: any) => {
    return ['Input', 'DummyInput', 'Placeholder'].indexOf(child?.type?.name) >= 0
  })

  return (
    <components.ValueContainer {...props}>
      {!props.selectProps.inputValue && valueLabel}
      {childrenToRender}
    </components.ValueContainer>
  )
}

export const Select: FC<Props> = ({
  value,
  label = '',
  className = '',
  options,
  required = false,
  title,
  optionLabel = 'label',
  optionValue = 'value',
  closeMenuOnSelect,
  helperText,
  placeholder,
  selectAllOrOne,
  filterOption,
  onChange,
  returnFullOption = false,
  ...restProps
}) => {
  const intl = useIntl()
  const [searchField, setSearchField] = useState<string | undefined>(undefined)
  const [filteredOptionsCount, setFilteredOptionsCount] = useState(0)

  const id = `${Math.random()}`
  const labelHasTranslation = label ? !!intl.messages[label] : false
  const placeholderHasTranslation = placeholder ? !!intl.messages[placeholder] : false
  const titleHasTranslation = title ? !!intl.messages[title] : false

  if (restProps.isMulti) {
    if (options) {
      const selectAllLength = options.filter(
        (option: any) => option?.value === CUSTOM_SELECT_ALL_VALUE
      )?.length
      const hasSelectAll = !!selectAllLength && selectAllLength > 0
      if (!hasSelectAll && options.length > 0) {
        options = [
          {label: intl.formatMessage({id: 'GENERAL.SELECT_ALL'}), value: CUSTOM_SELECT_ALL_VALUE},
          ...options,
        ]
      }
    }
  }

  const isIncludingString = useCallback(
    (option: any, searchText: string) => {
      if (option?.value === CUSTOM_SELECT_ALL_VALUE && filteredOptionsCount > 1) {
        return true
      }

      let check = true
      if (searchText) {
        check &&=
          option.label.toString().toLowerCase().includes(searchText.toLowerCase().trim()) ||
          option?.value.toString().toLowerCase().includes(searchText.toLowerCase().trim())
      }

      if (typeof filterOption === 'function') {
        check &&= filterOption(option, searchText)
      }
      return check
    },
    [filterOption, filteredOptionsCount]
  )

  const setSearch = (newValue: string) => {
    setSearchField(newValue)
  }

  const resetSearch = useCallback(() => {
    setSearchField(undefined)
    setFilteredOptionsCount(0)
  }, [setSearchField])

  const renderTooltip = useMemo(() => {
    return (
      <Tooltip id={`tooltip-${id}`}>
        {titleHasTranslation ? intl.formatMessage({id: title}) : title}
      </Tooltip>
    )
  }, [title, id, intl, titleHasTranslation])

  const selectedOptions = useMemo(() => {
    if (!options) {
      return undefined
    }

    if (restProps.isMulti) {
      const selectedOptions = []
      for (let i = 0; i < value.length; i++) {
        // eslint-disable-next-line eqeqeq
        const option = options.find((opt: any) => opt[optionValue] == value[i])
        selectedOptions.push(option)
      }
      return selectedOptions
    } else {
      // eslint-disable-next-line eqeqeq
      const option = options.find((opt: any) => opt[optionValue] == value)
      return option
    }
  }, [options, restProps.isMulti, value, optionValue])

  useEffect(() => {
    const optionsClone = _.clone(options)
    const filteredOptions = optionsClone?.filter((opt) => isIncludingString(opt, searchField || ''))
    setFilteredOptionsCount(filteredOptions?.length || 0)
  }, [isIncludingString, options, searchField, filterOption])

  return (
    <>
      {label && (
        <label className={clsx('form-label', {required})} htmlFor={id}>
          {labelHasTranslation ? intl.formatMessage({id: label}) : label}
          {title && (
            <OverlayTrigger placement='right' overlay={renderTooltip}>
              <div className='badge pe-auto'>
                <i className='bi bi-info-circle-fill'></i>
              </div>
            </OverlayTrigger>
          )}
        </label>
      )}

      <ReactSelect
        id={id}
        components={{
          Option,
          ValueContainer,
        }}
        className={clsx('form-control p-0', className)}
        classNames={{
          container: () => 'react-select-container',
          control: () => 'react-select__control',
          menu: () => 'react-select__menu',
          option: (props) =>
            `react-select__option ${props.isSelected ? `react-select__option-selected` : ''}`,
          indicatorSeparator: () => 'react-select__indicator-separator',
          placeholder: () => 'react-select__placeholder',
          input: () => 'react-select__input',
          singleValue: () => 'react-select__single-value',
        }}
        options={options}
        value={selectedOptions}
        onInputChange={(newValue, {action}) => {
          if (action === 'input-change') {
            setSearch(newValue)
          }
        }}
        inputValue={searchField}
        onMenuClose={() => {
          resetSearch()
        }}
        filterOption={isIncludingString}
        onChange={(option: any, actionMeta: ActionMeta<any>) => {
          if (typeof onChange !== 'function') return

          if (restProps.isMulti) {
            // ! DO NOT CHANGE: contact Bilal Khalid before making any change
            // * when select all is clicked
            if (actionMeta.option && actionMeta.option?.value === CUSTOM_SELECT_ALL_VALUE) {
              const optionsClone = _.clone(options)?.filter(
                (opt: any) => opt.value !== CUSTOM_SELECT_ALL_VALUE
              )

              let filteredOptions: OptionsOrGroups<unknown, GroupBase<unknown>> | undefined = []
              if (searchField) {
                filteredOptions = optionsClone?.filter(
                  (filteredOption) =>
                    isIncludingString(filteredOption, searchField) &&
                    !option.includes(filteredOption)
                )
              } else {
                filteredOptions = optionsClone
              }

              if (Array.isArray(selectedOptions) && Array.isArray(filteredOptions)) {
                for (let index = 0; index < filteredOptions.length; index++) {
                  const filteredOption = filteredOptions[index]
                  const alreadySelected =
                    selectedOptions.findIndex(
                      (selectedOption) => selectedOption.value === filteredOption.value
                    ) > -1

                  if (!alreadySelected && filteredOption.value !== CUSTOM_SELECT_ALL_VALUE) {
                    option.push(filteredOption)
                  }
                }
              } else {
                return
              }
            }

            let values = option
              .map((o: any) => o)
              .filter((v: any) => v.value !== CUSTOM_SELECT_ALL_VALUE)

            if (selectAllOrOne) {
              if (values.length === CUSTOM_SELECT_ALL_VALUE.length) {
                values = CUSTOM_SELECT_ALL_VALUE
              } else if (values.length > 0 && values.length !== CUSTOM_SELECT_ALL_VALUE.length) {
                values = values.filter((option: any) => !value.includes(option.value))
              } else {
                values = []
              }
            }

            onChange(returnFullOption ? values : values.map((v: any) => v.value))
          } else {
            setSearchField(undefined)
            onChange(returnFullOption ? option : option?.value)
          }
        }}
        closeMenuOnSelect={closeMenuOnSelect ?? restProps.isMulti ? false : undefined}
        placeholder={
          placeholder ? (
            <span className='text-muted'>
              {placeholderHasTranslation ? intl.formatMessage({id: placeholder}) : placeholder}
            </span>
          ) : label ? (
            <span className='text-muted'>
              {labelHasTranslation ? intl.formatMessage({id: label}) : label}
            </span>
          ) : (
            'Select an option'
          )
        }
        hideSelectedOptions={false}
        {...restProps}
      />
      {helperText && (
        <small id={`${id}-help-text`} className='form-text text-muted'>
          {helperText}
        </small>
      )}
    </>
  )
}
