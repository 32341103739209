import {
    GET_EMAIL_VERIFICATION_STATUS, GENERATE_EMAIL_OTP, VERIFY_EMAIL_OTP
  } from '../../Actions/UserSecurityDetails/ActionTypes/ApiActionTypes'
  import {takeEvery} from 'redux-saga/effects'
  import fetchData from '../SagaHelper'
  
  function* dataSaga() {
    yield takeEvery(GET_EMAIL_VERIFICATION_STATUS, fetchData)
    yield takeEvery(GENERATE_EMAIL_OTP, fetchData)
    yield takeEvery(VERIFY_EMAIL_OTP, fetchData)
  }
  
  export default dataSaga
  