import {useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {Outlet} from 'react-router-dom'
import {getGeneralSettings} from 'setup/redux/Actions/General/settings'
import {Footer} from './components/Footer'
import {toAbsoluteUrl} from '_metronic/helpers'

const FullLayout = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getGeneralSettings())
  }, [dispatch])

  return (
    <div
      className='d-flex flex-column min-vh-100 flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
      style={{
        backgroundImage: `url(${toAbsoluteUrl('/media/background/6.png')})`,
        backgroundSize: 'cover',
        backgroundPositionX: 'center',
        backgroundPositionY: 'center',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <div className='flex-grow-1 container-fluid my-5'>
        <Outlet />
      </div>

      <Footer />
    </div>
  )
}

export default FullLayout
