import {getTenantName} from 'common/HelperFunctions'

export const isAttendanceBetaEnabled = () => {
  const TENANTS_USING_OLD_ATTENDANCE_PORTAL = [
    'eureka',
    'lgs',
    'lyceum',
    'psm',
    'risc',
    'whales',
    'whss',
  ]
  return !TENANTS_USING_OLD_ATTENDANCE_PORTAL.includes(getTenantName())
}
