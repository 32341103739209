import {REQUEST_PURCHASE_TYPE, REQUEST_STATUS} from 'common/constants'

export const getRequestStatusString = (status: number) => {
  switch (status) {
    case REQUEST_STATUS.REJECTED:
      return 'REQUEST_STATUS.REJECTED'
    case REQUEST_STATUS.PENDING:
      return 'REQUEST_STATUS.PENDING'
    case REQUEST_STATUS.PENDING_APPROVAL_PURCHASE_COMMITTEE:
      return 'REQUEST_STATUS.PENDING_APPROVAL_PURCHASE_COMMITTEE'
    case REQUEST_STATUS.PENDING_APPROVAL_PRINCIPLE:
      return 'REQUEST_STATUS.PENDING_APPROVAL_PRINCIPLE'
    case REQUEST_STATUS.PENDING_APPROVAL_SCHOOL_MANAGEMENT:
      return 'REQUEST_STATUS.PENDING_APPROVAL_SCHOOL_MANAGEMENT'
    case REQUEST_STATUS.PENDING_APPROVAL_SENIOR_PRINCIPLE:
      return 'REQUEST_STATUS.PENDING_APPROVAL_SENIOR_PRINCIPLE'
    case REQUEST_STATUS.PENDING_APPROVAL_BOARD:
      return 'REQUEST_STATUS.PENDING_APPROVAL_BOARD'
    case REQUEST_STATUS.PENDING_APPROVAL_CHAIRMAN:
      return 'REQUEST_STATUS.PENDING_APPROVAL_CHAIRMAN'
    case REQUEST_STATUS.APPROVED:
      return 'REQUEST_STATUS.APPROVED'
    case REQUEST_STATUS.COMPLETED_BY_STAFF:
      return 'REQUEST_STATUS.COMPLETED_BY_STAFF'
    case REQUEST_STATUS.COMPLETED:
      return 'REQUEST_STATUS.COMPLETED'
    case REQUEST_STATUS.PENDING_PURCHASE_TYPE:
      return 'REQUEST_STATUS.PENDING_PURCHASE_TYPE'
    case REQUEST_STATUS.PENDING_ACCOUNTS_ADVANCE:
      return 'REQUEST_STATUS.PENDING_ACCOUNTS_ADVANCE'
    default:
      return 'N/A'
  }
}

export const getRequestStatusBadgeColor = (status: number) => {
  switch (status) {
    case REQUEST_STATUS.COMPLETED_BY_STAFF:
    case REQUEST_STATUS.COMPLETED:
      return 'badge-light-success'
    case REQUEST_STATUS.APPROVED:
      return 'badge-light-info'
    case REQUEST_STATUS.REJECTED:
      return 'badge-light-danger'
    default:
      return 'badge-light-primary'
  }
}

export const getRequestPurchaseTypeString = (purchaseType: number) => {
  switch (purchaseType) {
    case REQUEST_PURCHASE_TYPE.CREDIT:
      return 'REQUEST_PURCHASE_TYPE.CREDIT'
    case REQUEST_PURCHASE_TYPE.ADVANCE:
      return 'REQUEST_PURCHASE_TYPE.ADVANCE'
    default:
      return 'N/A'
  }
}
