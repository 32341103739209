import clsx from 'clsx'
import {getTenantLogo} from 'common/HelperFunctions'
import {FC, ImgHTMLAttributes, useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {useSelector} from 'react-redux'
import {RootState} from 'setup'
import {getBranchLogo} from 'setup/redux/Actions/Dashboard/dashboard'

type Props = {
  size?: 'big' | 'small'
  showBranchLogo?: boolean
} & ImgHTMLAttributes<HTMLImageElement>

const Logo: FC<Props> = ({
  size = 'big',
  alt,
  src,
  className,
  showBranchLogo = true,
  ...remainingProps
}) => {
  const dispatch = useDispatch()

  const generalSettings = useSelector<RootState>(
    ({mainReducer}) => mainReducer.generalSettings
  ) as any

  const [logoInitialized, setLogoInitialized] = useState(false)
  const [tenantLogo, setTenantLogo] = useState<string | null>(null)
  const [branchLogo, setBranchLogo] = useState<string | null>(null)
  const [logoFromSetting, setLogoFromSetting] = useState(true)

  const loadBranchLogo = () => {
    const onSuccess = (res: any) => {
      setBranchLogo(res.result.branch_logo)
      initLogo()
    }

    const onFailure = () => {
      initLogo()
    }

    dispatch(getBranchLogo(onSuccess, onFailure))
  }

  useEffect(() => {
    if (showBranchLogo) loadBranchLogo()
    else initLogo()

    return () => {
      setLogoInitialized(false)
      setTenantLogo(null)
      setBranchLogo(null)
      setLogoFromSetting(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generalSettings])

  const initLogo = () => {
    let logoUrl = getTenantLogo(size)

    if (logoUrl) setLogoFromSetting(false)

    if (showBranchLogo && branchLogo) {
      logoUrl = branchLogo
    }

    if (logoUrl) {
      setTenantLogo(logoUrl)
    }

    setLogoInitialized(true)
  }

  if (!tenantLogo || !logoInitialized) {
    return null
  }

  return (
    <img
      alt={alt ?? 'Logo'}
      src={tenantLogo}
      className={clsx(
        {
          'h-80px': size === 'big' && !logoFromSetting,
          'h-55px': size === 'small' && logoFromSetting,
          'h-40px': size === 'small' && !logoFromSetting,
        },
        className
      )}
      {...remainingProps}
    />
  )
}

export default Logo
