import {getTenantName} from 'common/HelperFunctions'
import {isModuleBetaEnabled} from '.'

export const isHomeworkBetaEnabled = () => {
  return ['whss', 'whales', 'psm', 'linksedu', 'tss', 'nis', 'eureka'].includes(getTenantName())
}

export const isNewHomeworkDisabled = () => {
  return ['risc', 'lgs', 'bhs', 'lyceum', 'maniyas', 'reflections'].includes(getTenantName())
}

export const isHomeworkBetaToggleEnabled = () => {
  return isModuleBetaEnabled('homework-beta')
}
