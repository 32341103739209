import clsx from 'clsx'
import {FC, useMemo} from 'react'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import Flatpickr, {DateTimePickerProps} from 'react-flatpickr'
import {useIntl} from 'react-intl'

type DatePickerProps = {
  label?: string
  isClearable?: boolean
  onClear?: () => void
  groupClass?: string
  helperText?: string
} & DateTimePickerProps

export const DatePicker: FC<DatePickerProps> = ({
  label,
  isClearable,
  onClear,
  placeholder,
  groupClass,
  options,
  title,
  helperText,
  ...props
}) => {
  const intl = useIntl()
  const id = `${Math.random()}`

  const labelHasTranslation = label ? !!intl.messages[label] : false
  const titleHasTranslation = title ? !!intl.messages[title] : false

  const renderTooltip = useMemo(() => {
    return (
      <Tooltip id={`tooltip-${id}`}>
        {titleHasTranslation ? intl.formatMessage({id: title}) : title}
      </Tooltip>
    )
  }, [title, id, intl, titleHasTranslation])

  let hasValue = false
  if (props.value) {
    if (Array.isArray(props.value)) {
      hasValue = props.value.some((value) => !!value)
    } else {
      hasValue = !!props.value
    }
  }

  return (
    <div className={clsx(groupClass)}>
      {label && (
        <label className={clsx('form-label', {required: props.required})} htmlFor={id}>
          {labelHasTranslation ? intl.formatMessage({id: label}) : label}
          {title && (
            <OverlayTrigger placement='right' overlay={renderTooltip}>
              <div className='badge pe-auto'>
                <i className='bi bi-info-circle-fill'></i>
              </div>
            </OverlayTrigger>
          )}
        </label>
      )}

      <div className='position-relative'>
        <Flatpickr
          className='form-control me-9'
          placeholder={placeholder || intl.$t({id: 'GENERAL.SELECT_DATE'})}
          options={{
            dateFormat: 'd-m-Y',
            ...options,
          }}
          {...props}
        />
        {isClearable && hasValue && props.value && (
          <button
            type='button'
            className='btn btn-icon btn-sm btn-light-danger rounded-circle h-25px w-25px me-2 position-absolute p-1 end-0 top-50 translate-middle-y'
            onClick={() => {
              if (onClear) onClear()
            }}
          >
            <i className='fa fa-xmark' />
          </button>
        )}
      </div>

      {helperText && (
        <small id={`${id}-help-text`} className='form-text text-muted'>
          {helperText}
        </small>
      )}
    </div>
  )
}
