import {takeEvery} from 'redux-saga/effects'
import {
  GET_ALL_TENANT_STATS,
  GET_ALL_TENANTS,
  GET_ONLINE_USER_STATS,
} from 'setup/redux/Actions/School/Stats/ActionTypes/ApiActionTypes'
import fetchData from '../../SagaHelper'

function* dataSaga() {
  yield takeEvery(GET_ONLINE_USER_STATS, fetchData)
  yield takeEvery(GET_ALL_TENANT_STATS, fetchData)
  yield takeEvery(GET_ALL_TENANTS, fetchData)
}

export default dataSaga
