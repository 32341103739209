import {Component} from 'react'
import {Slide, ToastContainer} from 'react-toastify'
import {AppRoutes} from 'app/routing/AppRoutes'
import {getLang, isRtl} from 'common/i18n/Metronici18n'
import {getTheme} from 'common/themes/ThemeHelpers'

type AppState = {
  themeStyleLoaded: Boolean
}

export default class App extends Component<{}, AppState> {
  setThemeLoaded() {
    this.setState({
      themeStyleLoaded: true,
    })
  }

  loadTheme() {
    // get theme config
    const theme = getTheme()
    const rtl = isRtl()
    const lang = getLang()

    const body = document.getElementById('kt_body')
    if (body) {
      document.documentElement.setAttribute('lang', lang.selectedLang)
      if (rtl) {
        body.setAttribute('dir', 'rtl')
        body.setAttribute('direction', 'rtl')
        body.style.direction = 'rtl'

        if (['ur', 'ar'].includes(lang.selectedLang)) {
          // body.style.fontFamily = 'Jameel Noori Nastaleeq Regular'
          body.style.fontFamily = "'Noto Nastaliq Urdu', serif"
        }
      }

      if (theme.selectedTheme === 'dark') {
        body.classList.add('dark-mode')
        if (rtl) import('common/themes/RTLdark').then(() => this.setThemeLoaded())
        else import('common/themes/dark').then(() => this.setThemeLoaded())
      } else {
        body.classList.remove('dark-mode')
        if (rtl) import('common/themes/RTLlight').then(() => this.setThemeLoaded())
        else import('common/themes/light').then(() => this.setThemeLoaded())
      }
    }
  }

  componentDidMount() {
    this.loadTheme()
  }

  render() {
    const rtl = isRtl()
    const theme = getTheme()

    // Render app routes if theme is loaded
    return this.state && this.state.themeStyleLoaded ? (
      <>
        <ToastContainer
          position='top-right'
          autoClose={3000}
          closeButton={true}
          transition={Slide}
          hideProgressBar={true}
          rtl={rtl}
          theme={theme.selectedTheme}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <AppRoutes />
      </>
    ) : null
  }
}
