import {RouteObject} from 'react-router-dom'
import {USER_TYPES_NUMERIC} from './user'

export const ATTENDANCE_SHOW_ROUTES: RouteObject[] = [
  {path: '/institute/attendance/*'},
  {path: '/institute/attendanceReport/*'},
  {path: '/institute/userCustomAtendanceSchedule/admin'},
]

export const ATTENDANCE_BETA_TOGGLE_TENANTS = [
  'demo',
  'democollege',
  'myschool',
  'mgs',
  'lihs',
  'wlgs',
  'concordia',
  'rayden',
  'nis',
  'pss',
]

export const ATTENDANCE_URL_FOR_BRANCH_SELECTION = [
  '/institute/attendance/teacher',

  '/institute/attendance/teacher-report/individual',
  '/institute/attendance/teacher-report/combine',
  '/institute/attendance/teacher-report/monthly',
  '/institute/attendance/teacher-report/monthly-combine',

  '/institute/attendance/teacher-subject',
  '/institute/attendance/teacher-subject-report/individual',
  '/institute/attendance/teacher-subject-report/combine',
  '/institute/attendance/teacher-subject-report/roster',

  '/institute/attendance/student',
  '/institute/attendance/student-report/individual',
  '/institute/attendance/student-report/combine',
  '/institute/attendance/student-report/classwise',
  '/institute/attendance/student-report/monthwise-summary',

  '/institute/attendance/student-subject',
  '/institute/attendance/student-subject-report/individual',
  '/institute/attendance/student-subject/combined-report',
  '/institute/attendance/student-subject-report/combine',
  '/institute/attendance/student-subject-report/monthly',
  '/institute/attendance/student-subject-report/subjectwise-summary',
]

export const REPORT_TITLE_CONSTANTS = {
  TEACHER_STAFF_INDIVIDUAL: 'TEACHER_STAFF_INDIVIDUAL',
  TEACHER_STAFF_COMBINE: 'TEACHER_STAFF_COMBINE',
  TEACHER_STAFF_MONTHLY_INDIVIDUAL: 'TEACHER_STAFF_MONTHLY_INDIVIDUAL',
  TEACHER_STAFF_MONTHLY_COMBINE: 'TEACHER_STAFF_MONTHLY_COMBINE',

  TEACHER_SUBJECT_INDIVIDUAL: 'TEACHER_SUBJECT_INDIVIDUAL',
  TEACHER_SUBJECT_COMBINE: 'TEACHER_SUBJECT_COMBINE',
  TEACHER_SUBJECT_ROSTER: 'TEACHER_SUBJECT_ROSTER',

  STUDENT_INDIVIDUAL: 'STUDENT_INDIVIDUAL',
  STUDENT_COMBINE: 'STUDENT_COMBINE',
  STUDENT_CLASSWISE: 'STUDENT_CLASSWISE',
  STUDENT_MONTHWISE: 'STUDENT_MONTHWISE',

  STUDENT_SUBJECT_INDIVIDUAL: 'STUDENT_SUBJECT_INDIVIDUAL',
  STUDENT_SUBJECT_COMBINE: 'STUDENT_SUBJECT_COMBINE',
  STUDENT_SUBJECT_MONTHLY: 'STUDENT_SUBJECT_MONTHLY',
  STUDENT_SUBJECT_SUBJECTWISE: 'STUDENT_SUBJECT_SUBJECTWISE',
}

export const getReportTitle: (title: string, type: any, data?: any) => string = (
  title,
  type,
  data = null
) => {
  if (title === REPORT_TITLE_CONSTANTS.TEACHER_STAFF_INDIVIDUAL) {
    if (type.length > 1) {
      return 'All Teacher(s) / Staff Attendance Report'
    }
    return data[0]?.full_name + "'s Attendance Report"
  } else if (title === REPORT_TITLE_CONSTANTS.TEACHER_STAFF_COMBINE) {
    if (type.length > 1) {
      return 'All Teacher(s) / Staff Attendance Report'
    } else {
      if (type[0] === USER_TYPES_NUMERIC.TEACHER) {
        return 'All Teacher(s) Attendance Report'
      }
      return 'All Staff Attendance Report'
    }
  } else if (title === REPORT_TITLE_CONSTANTS.TEACHER_STAFF_MONTHLY_INDIVIDUAL) {
  } else if (title === REPORT_TITLE_CONSTANTS.TEACHER_STAFF_MONTHLY_COMBINE) {
  } else if (title === REPORT_TITLE_CONSTANTS.TEACHER_SUBJECT_INDIVIDUAL) {
    if (type.length > 1) {
      return 'All Teacher(s) Attendance Report'
    }
    return data[0]?.full_name + "'s Attendance Report"
  } else if (title === REPORT_TITLE_CONSTANTS.TEACHER_SUBJECT_COMBINE) {
  } else if (title === REPORT_TITLE_CONSTANTS.TEACHER_SUBJECT_ROSTER) {
  } else if (title === REPORT_TITLE_CONSTANTS.STUDENT_INDIVIDUAL) {
  } else if (title === REPORT_TITLE_CONSTANTS.STUDENT_COMBINE) {
  } else if (title === REPORT_TITLE_CONSTANTS.STUDENT_CLASSWISE) {
  } else if (title === REPORT_TITLE_CONSTANTS.STUDENT_MONTHWISE) {
  } else if (title === REPORT_TITLE_CONSTANTS.STUDENT_SUBJECT_INDIVIDUAL) {
    return data[0]?.full_name + "'s Attendance Report"
  } else if (title === REPORT_TITLE_CONSTANTS.STUDENT_SUBJECT_COMBINE) {
    if (type.length > 1) {
      return 'All Subjects(s) Attendance Report'
    }
    return data[0]?.subject_name + ' Attendance Report'
  } else if (title === REPORT_TITLE_CONSTANTS.STUDENT_SUBJECT_MONTHLY) {
  } else if (title === REPORT_TITLE_CONSTANTS.STUDENT_SUBJECT_SUBJECTWISE) {
  }
  return 'Attendance Report'
}
