import {toAbsoluteUrl} from '_metronic/helpers'
import {Color} from 'common/components/grid/types'
import {DEVICE_TYPES} from 'common/constants'

export * from './attendance'
export * from './library'
export * from './request'
export * from './user'
export * from './homework'

export const isModuleBetaEnabled = (key: string) => {
  return localStorage.getItem(key) === 'true'
}

export const toggleModuleBeta = (key: string) => {
  if (isModuleBetaEnabled(key)) {
    localStorage.removeItem(key)
  } else {
    localStorage.setItem(key, 'true')
  }
  window.location.href = '/'
}

export const getColorForString = (string: string) => {
  const colors = ['primary', 'secondary', 'success', 'danger', 'warning', 'info']

  let hash = 0
  for (let i = 0; i < string.length; i++) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash)
  }
  return colors[Math.abs(hash) % colors.length] as Color
}

export const months = () => {
  return [
    {label: 'SUBJECTS.JANUARY', value: 1},
    {label: 'SUBJECTS.FEBRUARY', value: 2},
    {label: 'SUBJECTS.MARCH', value: 3},
    {label: 'SUBJECTS.APRIL', value: 4},
    {label: 'SUBJECTS.MAY', value: 5},
    {label: 'SUBJECTS.JUNE', value: 6},
    {label: 'SUBJECTS.JULY', value: 7},
    {label: 'SUBJECTS.AUGUST', value: 8},
    {label: 'SUBJECTS.SEPTEMBER', value: 9},
    {label: 'SUBJECTS.OCTOBER', value: 10},
    {label: 'SUBJECTS.NOVEMBER', value: 11},
    {label: 'SUBJECTS.DECEMBER', value: 12},
  ]
}

export const extensionToMimeType: {[key: string]: string} = {
  pdf: 'application/pdf',
  png: 'image/png',
  jpg: 'image/jpeg',
  jpeg: 'image/jpeg',
  gif: 'image/gif',
  svg: 'image/svg+xml',
}

export const feeInstallments = () => {
  const installmentsCount = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]

  return installmentsCount.map((count) => ({
    label: count.toString(),
    value: count,
  }))
}

export const getDeviceTypeLabel = (deviceType: number) => {
  switch (deviceType) {
    case DEVICE_TYPES.ANDROID:
    case DEVICE_TYPES.ANDROID_NEW:
      return 'DEVICE_TYPES.ANDROID'
    case DEVICE_TYPES.IOS:
    case DEVICE_TYPES.IOS_NEW:
      return 'DEVICE_TYPES.IOS'
    case DEVICE_TYPES.HYBRID:
      return 'DEVICE_TYPES.HYBRID'
    default:
      return 'DEVICE_TYPES.WEB'
  }
}

export const getDeviceTypeIcon = (deviceType: number) => {
  switch (deviceType) {
    case DEVICE_TYPES.ANDROID:
    case DEVICE_TYPES.ANDROID_NEW:
    case DEVICE_TYPES.HYBRID_ANDROID:
      return toAbsoluteUrl('/media/icons/duotune/social/soc002.svg')
    case DEVICE_TYPES.IOS:
    case DEVICE_TYPES.IOS_NEW:
      return toAbsoluteUrl('/media/icons/duotune/social/soc001.svg')
    case DEVICE_TYPES.HYBRID:
      return toAbsoluteUrl('/media/icons/duotune/electronics/elc002.svg')
    default:
      return toAbsoluteUrl('/media/icons/duotune/electronics/elc004.svg')
  }
}

export const calculateErrorMessage = (error: any, defaultMessage?: string): string => {
  return (
    error?.response?.data?.message ||
    error?.response?.message ||
    error?.message ||
    defaultMessage ||
    'Something went wrong'
  )
}
