import {
  CONFIRM_FILE_UPLOAD,
  GET_SIGNED_UPLOAD_URL,
  GET_TENANT_SESSIONS,
  REDUX_TENANT_SESSIONS,
} from './ActionTypes/ApiActionTypes'
import constants from 'common/constants'

/**
 * Get all sessions of current tenant
 * @param {(res: any) => void} onSuccess
 * @param {(err: any) => void} onFailure
 * @returns
 */
export const getTenantSessions = (onSuccess, onFailure = null) => {
  return {
    type: GET_TENANT_SESSIONS,
    payload: {
      apiUrl: constants.api_get_tenant_sessions,
      getString: '',
      useBaseURL: true,
      requestType: 'GET',
      header: 'application/json',
      reduxActionType: REDUX_TENANT_SESSIONS,
      onSuccess,
      onFailure,
    },
  }
}

/**
 * Get signed upload url
 * @param {*} body
 * @param {(res: any) => void} onSuccess
 * @param {(err: any) => void} onFailure
 * @returns
 */
export const getSignedUploadUrl = (body, onSuccess, onFailure) => {
  return {
    type: GET_SIGNED_UPLOAD_URL,
    payload: {
      apiUrl: constants.api_get_signed_upload_url,
      getString: '',
      useBaseURL: true,
      body,
      requestType: 'POST',
      header: 'application/json',
      reduxActionType: '',
      onSuccess,
      onFailure,
    },
  }
}

export const confirmFileUpload = (body, onSuccess, onFailure) => {
  return {
    type: CONFIRM_FILE_UPLOAD,
    payload: {
      onSuccess,
      onFailure,
      apiUrl: constants.api_confirm_file_upload,
      getString: '',
      useBaseURL: true,
      requestType: 'POST',
      header: 'application/json',
      reduxActionType: '',
      body,
    },
  }
}
