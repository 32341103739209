import {calculateErrorMessage} from 'common/helpers'
import {Alert} from 'react-bootstrap'
import {useIntl} from 'react-intl'

const ErrorAlert = ({error}: {error: unknown}) => {
  const intl = useIntl()
  return (
    <Alert variant='danger'>
      <Alert.Heading>Error</Alert.Heading>
      <p>
        {calculateErrorMessage(error, intl.formatMessage({id: 'GENERAL.SOMETHING_WENT_WRONG'}))}
      </p>
    </Alert>
  )
}

export default ErrorAlert
