export const GET_TESTS = 'GET_TESTS'
export const GET_ONE_TEST = 'GET_ONE_TEST'
export const DELETE_SINGLE_TEST = 'DELETE_SINGLE_TEST'
export const DELETE_MANY_TESTS = 'DELETE_MANY_TESTS'
export const GET_TEST_RESOURCES = 'GET_TEST_RESOURCES'
export const ADD_TEST_RESOURCE = 'ADD_TEST_RESOURCE'
export const DELETE_TEST_RESOURCE = 'DELETE_TEST_RESOURCE'
export const ADD_SINGLE_TEST = 'ADD_SINGLE_TEST'
export const UPDATE_TEST = 'UPDATE_TEST'
export const GRADEWISE_TEST_LIMIT = 'GRADEWISE_TEST_LIMIT'
export const ASSIGN_TOP_TESTS = 'ASSIGN_TOP_TESTS'
export const GET_TEST_RESULTS = 'GET_TEST_RESULTS'
export const UPDATE_TEST_RESULT = 'UPDATE_TEST_RESULT'
export const ASSIGN_MARKS_TO_ALL_TESTS = 'ASSIGN_MARKS_TO_ALL_TESTS'
export const SEND_TEST_NOTIFICATION = 'SEND_TEST_NOTIFICATION'
export const SEND_TEST_SMS = 'SEND_TEST_SMS'
export const PRINT_TEST_PDF = 'PRINT_TEST_PDF'
export const GRADEWISE_TEST_TYPES = 'GRADEWISE_TEST_TYPES'
export const ADD_MANY_TEST = 'ADD_MANY_TEST'
export const RESET_QUIZ = 'RESET_QUIZ'
export const GET_TEST_TYPES = 'GET_TEST_TYPES'