import {
  GET_FEE_HISTORY,
  DOWNLOAD_VOUCHER,
  GET_STUDENT_PROFILE_FEE_PARTICULARS,
  GET_CLASS_FEE_PACKAGE_DETAILS,
} from '../../Actions/Fee/ActionTypes/ApiActionTypes'
import fetchData from '../SagaHelper'
import {takeEvery} from 'redux-saga/effects'

function* dataSaga() {
  yield takeEvery(GET_FEE_HISTORY, fetchData)
  yield takeEvery(DOWNLOAD_VOUCHER, fetchData)
  yield takeEvery(GET_STUDENT_PROFILE_FEE_PARTICULARS, fetchData)
  yield takeEvery(GET_CLASS_FEE_PACKAGE_DETAILS, fetchData)
}

export default dataSaga
