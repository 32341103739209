import {takeEvery} from 'redux-saga/effects'

import {
  GET_HUBSPOT_TICKETS,
  GET_HUBSPOT_TICKET,
  CREATE_HUBSPOT_TICKET,
  UPDATE_HUBSPOT_TICKET,
  DELETE_HUBSPOT_TICKET,
  REPLY_HUBSPOT_TICKET,
} from 'setup/redux/Actions/TicktingFunctonality/ActionTypes/ApiActionTypes'

import fetchData from '../../SagaHelper'

function* dataSaga() {
  yield takeEvery(GET_HUBSPOT_TICKETS, fetchData)
  yield takeEvery(GET_HUBSPOT_TICKET, fetchData)
  yield takeEvery(CREATE_HUBSPOT_TICKET, fetchData)
  yield takeEvery(UPDATE_HUBSPOT_TICKET, fetchData)
  yield takeEvery(DELETE_HUBSPOT_TICKET, fetchData)
  yield takeEvery(REPLY_HUBSPOT_TICKET, fetchData)
}

export default dataSaga
