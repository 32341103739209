import * as T from '../../../Actions/School/AdmissionSettings/ActionTypes/ApiActionTypes'
import fetchData from '../../SagaHelper'
import {takeEvery} from 'redux-saga/effects'

function* dataSaga() {
  // admission subject groups
  yield takeEvery(T.GET_SUBJECT_GROUPS, fetchData)
  yield takeEvery(T.GET_SUBJECT_GROUP, fetchData)
  yield takeEvery(T.CREATE_SUBJECT_GROUP, fetchData)
  yield takeEvery(T.UPDATE_SUBJECT_GROUP, fetchData)
  yield takeEvery(T.DELETE_SUBJECT_GROUP, fetchData)

  // admission subjects
  yield takeEvery(T.GET_ADMISSION_SUBJECTS, fetchData)
  yield takeEvery(T.GET_ADMISSION_SUBJECT, fetchData)
  yield takeEvery(T.CREATE_ADMISSION_SUBJECT, fetchData)
  yield takeEvery(T.UPDATE_ADMISSION_SUBJECT, fetchData)
  yield takeEvery(T.DELETE_ADMISSION_SUBJECT, fetchData)

  // admission questions
  yield takeEvery(T.GET_ADMISSION_QUESTIONS, fetchData)
  yield takeEvery(T.GET_ADMISSION_QUESTION, fetchData)
  yield takeEvery(T.CREATE_ADMISSION_QUESTION, fetchData)
  yield takeEvery(T.UPDATE_ADMISSION_QUESTION, fetchData)
  yield takeEvery(T.DELETE_ADMISSION_QUESTION, fetchData)

  // admission subject exams
  yield takeEvery(T.GET_SUBJECT_EXAMS, fetchData)
  yield takeEvery(T.GET_SUBJECT_EXAM, fetchData)
  yield takeEvery(T.CREATE_SUBJECT_EXAM, fetchData)
  yield takeEvery(T.UPDATE_SUBJECT_EXAM, fetchData)
  yield takeEvery(T.DELETE_SUBJECT_EXAM, fetchData)

  // admission subjects grdaes
  yield takeEvery(T.GET_SUBJECT_GRADES, fetchData)
  yield takeEvery(T.GET_SUBJECT_GRADE, fetchData)
  yield takeEvery(T.CREATE_SUBJECT_GRADE, fetchData)
  yield takeEvery(T.UPDATE_SUBJECT_GRADE, fetchData)
  yield takeEvery(T.DELETE_SUBJECT_GRADE, fetchData)

  // previous subjects
  yield takeEvery(T.GET_PREVIOUS_SUBJECTS, fetchData)
  yield takeEvery(T.GET_PREVIOUS_SUBJECT, fetchData)
  yield takeEvery(T.CREATE_PREVIOUS_SUBJECT, fetchData)
  yield takeEvery(T.UPDATE_PREVIOUS_SUBJECT, fetchData)
  yield takeEvery(T.DELETE_PREVIOUS_SUBJECT, fetchData)

  // subject combinations
  yield takeEvery(T.GET_SUBJECT_COMBINATIONS, fetchData)
  yield takeEvery(T.GET_SUBJECT_COMBINATION, fetchData)
  yield takeEvery(T.CREATE_SUBJECT_COMBINATION, fetchData)
  yield takeEvery(T.UPDATE_SUBJECT_COMBINATION, fetchData)
  yield takeEvery(T.DELETE_SUBJECT_COMBINATION, fetchData)

  // subject components
  yield takeEvery(T.GET_SUBJECT_COMPONENTS, fetchData)
  yield takeEvery(T.GET_SUBJECT_COMPONENT, fetchData)
  yield takeEvery(T.CREATE_SUBJECT_COMPONENT, fetchData)
  yield takeEvery(T.UPDATE_SUBJECT_COMPONENT, fetchData)
  yield takeEvery(T.DELETE_SUBJECT_COMPONENT, fetchData)

  // previous classes
  yield takeEvery(T.GET_PREVIOUS_CLASSES, fetchData)
  yield takeEvery(T.GET_PREVIOUS_CLASS, fetchData)
  yield takeEvery(T.CREATE_PREVIOUS_CLASS, fetchData)
  yield takeEvery(T.UPDATE_PREVIOUS_CLASS, fetchData)
  yield takeEvery(T.DELETE_PREVIOUS_CLASS, fetchData)

  // previous schools
  yield takeEvery(T.GET_PREVIOUS_SCHOOLS, fetchData)
  yield takeEvery(T.GET_PREVIOUS_SCHOOL, fetchData)
  yield takeEvery(T.CREATE_PREVIOUS_SCHOOL, fetchData)
  yield takeEvery(T.UPDATE_PREVIOUS_SCHOOL, fetchData)
  yield takeEvery(T.DELETE_PREVIOUS_SCHOOL, fetchData)

  // admission stages
  yield takeEvery(T.GET_ADMISSION_STAGES, fetchData)
  yield takeEvery(T.GET_ADMISSION_STAGE, fetchData)
  yield takeEvery(T.UPDATE_ADMISSION_STAGE, fetchData)
}

export default dataSaga
