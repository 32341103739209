import {takeEvery} from 'redux-saga/effects'
import {
  ATTENDANCE_UPLOAD,
  GET_STD_MOTNTHWISE_ATTENDANCE,
  GET_TODAY_ATTENDANCE,
  ATTENDANCE_SETTING,
  UPDATE_ATTENDANCE_SETTING,
  ATTENDANCE_ADD_ATTENDANCE_REMARKS,
  MARK_USER_ATTENDANCE,
  UNMARK_USER_ATTENDANCE,
  ATTENDANCE_SETTINGS_BY_BRANCH,
  UPDATE_USER_ATTENDANCE,
  GENERATE_TEACHER_STAFF_INDIVIDUAL_ATTENDANCE_REPORT,
  GENERATE_TEACHER_STAFF_COMBINE_ATTENDANCE_REPORT,
  GENERATE_TEACHER_SUBJECT_INDIVIDUAL_ATTENDANCE_REPORT,
  GENERATE_TEACHER_SUBJECT_COMBINE_ATTENDANCE_REPORT,
  GENERATE_STUDENT_SUBJECT_INDIVIDUAL_ATTENDANCE_REPORT,
  GENERATE_STUDENT_SUBJECT_COMBINED_ATTENDANCE_REPORT,
  GENERATE_STUDENT_SUBJECT_MONTHLY_ATTENDANCE_REPORT,
} from 'setup/redux/Actions/Attendance/ActionTypes/ApiActionTypes'
import fetchData from '../SagaHelper'

function* dataSaga() {
  yield takeEvery(ATTENDANCE_UPLOAD, fetchData)
  yield takeEvery(GET_TODAY_ATTENDANCE, fetchData)
  yield takeEvery(GET_STD_MOTNTHWISE_ATTENDANCE, fetchData)
  yield takeEvery(MARK_USER_ATTENDANCE, fetchData)
  yield takeEvery(UNMARK_USER_ATTENDANCE, fetchData)
  yield takeEvery(ATTENDANCE_SETTING, fetchData)
  yield takeEvery(UPDATE_ATTENDANCE_SETTING, fetchData)
  yield takeEvery(ATTENDANCE_ADD_ATTENDANCE_REMARKS, fetchData)
  yield takeEvery(ATTENDANCE_SETTINGS_BY_BRANCH, fetchData)
  yield takeEvery(UPDATE_USER_ATTENDANCE, fetchData)
  yield takeEvery(GENERATE_TEACHER_STAFF_INDIVIDUAL_ATTENDANCE_REPORT, fetchData)
  yield takeEvery(GENERATE_TEACHER_STAFF_COMBINE_ATTENDANCE_REPORT, fetchData)
  yield takeEvery(GENERATE_TEACHER_STAFF_COMBINE_ATTENDANCE_REPORT, fetchData)
  yield takeEvery(GENERATE_TEACHER_SUBJECT_INDIVIDUAL_ATTENDANCE_REPORT, fetchData)
  yield takeEvery(GENERATE_TEACHER_SUBJECT_COMBINE_ATTENDANCE_REPORT, fetchData)
  yield takeEvery(GENERATE_STUDENT_SUBJECT_INDIVIDUAL_ATTENDANCE_REPORT, fetchData)
  yield takeEvery(GENERATE_STUDENT_SUBJECT_COMBINED_ATTENDANCE_REPORT, fetchData)
  yield takeEvery(GENERATE_STUDENT_SUBJECT_MONTHLY_ATTENDANCE_REPORT, fetchData)
}

export default dataSaga
