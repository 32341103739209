import { take } from 'lodash'
import fetchData from '../SagaHelper'
import {takeEvery} from 'redux-saga/effects'
import { GET_TESTS, GET_ONE_TEST, DELETE_SINGLE_TEST,GET_TEST_TYPES, DELETE_MANY_TESTS, GET_TEST_RESOURCES, RESET_QUIZ , ADD_TEST_RESOURCE, DELETE_TEST_RESOURCE, ADD_SINGLE_TEST, UPDATE_TEST, GRADEWISE_TEST_LIMIT, ASSIGN_TOP_TESTS, GET_TEST_RESULTS, UPDATE_TEST_RESULT, ASSIGN_MARKS_TO_ALL_TESTS, SEND_TEST_NOTIFICATION, SEND_TEST_SMS, PRINT_TEST_PDF, GRADEWISE_TEST_TYPES, ADD_MANY_TEST} from 'setup/redux/Actions/Test/ActionTypes/ApiActionTypes'

function* dataSaga() {
    yield takeEvery(GET_TESTS, fetchData)
    yield takeEvery(GET_ONE_TEST, fetchData)
    yield takeEvery(DELETE_SINGLE_TEST, fetchData)
    yield takeEvery(DELETE_MANY_TESTS, fetchData)
    yield takeEvery(GET_TEST_RESOURCES, fetchData)
    yield takeEvery(ADD_TEST_RESOURCE, fetchData)
    yield takeEvery(DELETE_TEST_RESOURCE, fetchData)
    yield takeEvery(ADD_SINGLE_TEST, fetchData)
    yield takeEvery(UPDATE_TEST, fetchData)
    yield takeEvery(GRADEWISE_TEST_LIMIT, fetchData)
    yield takeEvery(ASSIGN_TOP_TESTS, fetchData)
    yield takeEvery(GET_TEST_RESULTS, fetchData)
    yield takeEvery(UPDATE_TEST_RESULT, fetchData)
    yield takeEvery(ASSIGN_MARKS_TO_ALL_TESTS, fetchData)
    yield takeEvery(SEND_TEST_NOTIFICATION, fetchData)
    yield takeEvery(SEND_TEST_SMS, fetchData)
    yield takeEvery(PRINT_TEST_PDF, fetchData)
    yield takeEvery(GRADEWISE_TEST_TYPES, fetchData)
    yield takeEvery(ADD_MANY_TEST, fetchData)
    yield takeEvery(RESET_QUIZ, fetchData)
    yield takeEvery(GET_TEST_TYPES, fetchData)
  }
  
  export default dataSaga