import {LIBRARY_BOOK_RESERVATION_STATUS} from 'common/constants'

export const getLibraryBookReservationStatusString = (status: number) => {
  switch (status) {
    case LIBRARY_BOOK_RESERVATION_STATUS.LATE:
      return 'LIBRARY.BOOK_RESERVATION.STATUS.LATE'
    case LIBRARY_BOOK_RESERVATION_STATUS.RESERVED:
      return 'LIBRARY.BOOK_RESERVATION.STATUS.RESERVED'
    case LIBRARY_BOOK_RESERVATION_STATUS.RETURNED:
      return 'LIBRARY.BOOK_RESERVATION.STATUS.RETURNED'
    case LIBRARY_BOOK_RESERVATION_STATUS.PENDING_RENEWAL:
      return 'LIBRARY.BOOK_RESERVATION.STATUS.PENDING_RENEWAL'
    case LIBRARY_BOOK_RESERVATION_STATUS.REJECTED_RENEWAL:
      return 'LIBRARY.BOOK_RESERVATION.STATUS.REJECTED_RENEWAL'
    default:
      return 'N/A'
  }
}

export const getLibraryBookReservationStatusColor = (status: number) => {
  switch (status) {
    case LIBRARY_BOOK_RESERVATION_STATUS.LATE:
      return 'light-warning'
    case LIBRARY_BOOK_RESERVATION_STATUS.RESERVED:
      return 'light-primary'
    case LIBRARY_BOOK_RESERVATION_STATUS.RETURNED:
      return 'light-success'
    case LIBRARY_BOOK_RESERVATION_STATUS.PENDING_RENEWAL:
      return 'light-info'
    case LIBRARY_BOOK_RESERVATION_STATUS.REJECTED_RENEWAL:
      return 'light-danger'
    default:
      return 'light-primary'
  }
}
