import {KTCard, KTCardBody, toAbsoluteUrl} from '_metronic/helpers'
import Spinner from 'common/components/Spinner'
import {FC} from 'react'
import {useIntl} from 'react-intl'

type Props = {
  stats?: {
    students: number
    parents: number
    staff: number
    teachers: number
  }
  total?: number
  loading?: boolean
}

const OnlineUsers: FC<Props> = ({stats, total, loading}) => {
  const intl = useIntl()

  if (loading || !stats || total === undefined) {
    return (
      <KTCard>
        <KTCardBody>
          <div className='center p-5'>
            <Spinner color='primary' />
          </div>
        </KTCardBody>
      </KTCard>
    )
  }

  return (
    <div className='card card-flush h-md-auto'>
      <div
        className='card-header rounded bgi-no-repeat bgi-size-cover bgi-position-y-top bgi-position-x-center h-250px position-relative'
        style={{backgroundImage: `url(${toAbsoluteUrl('/media/misc/pattern-2.png')})`}}
      >
        <div className='position-absolute top-0 start-0 mt-5 ms-5 w-100'>
          <span className='text-white fw-bold fs-2'>Currently Online Users</span>

          <div className='float-end text-white fw-bold fs-2 me-10'>{total.toLocaleString()}</div>
        </div>
        <h3 className='card-title align-items-center flex-column text-white'>
          <span className='fw-bolder fs-2x mb-3'>{stats.students.toLocaleString()}</span>
          <span className='text-white fw-bold fs-6'>
            {intl.formatMessage({id: 'MENU.STUDENTS'})}
          </span>
        </h3>
        <h3 className='card-title align-items-center flex-column text-white'>
          <span className='fw-bolder fs-2x mb-3'>{stats.parents.toLocaleString()}</span>
          <span className='text-white fw-bold fs-6'>
            {intl.formatMessage({id: 'MENU.PARENTS'})}
          </span>
        </h3>
        <h3 className='card-title align-items-center flex-column text-white'>
          <span className='fw-bolder fs-2x mb-3'>{stats.staff.toLocaleString()}</span>
          <span className='text-white fw-bold fs-6'>{intl.formatMessage({id: 'MENU.STAFF'})}</span>
        </h3>
        <h3 className='card-title align-items-center flex-column text-white'>
          <span className='fw-bolder fs-2x mb-3'>{stats.teachers.toLocaleString()}</span>
          <span className='text-white fw-bold fs-6'>
            {intl.formatMessage({id: 'MENU.TEACHERS'})}
          </span>
        </h3>
      </div>
    </div>
  )
}

export default OnlineUsers
