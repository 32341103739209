import {KTCard, KTCardBody} from '_metronic/helpers'
import Spinner from 'common/components/Spinner'
import {FC, useMemo} from 'react'
import {CardTitle} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import Chart from 'react-apexcharts'
import {getCSSVariableValue} from '_metronic/assets/ts/_utils'

type Stats = {
  web: number
  android: number
  ios: number
}

type TableProps = {
  stats: Stats
  total: number
  heading: string
}

const StatsView: FC<TableProps> = ({stats, total, heading}) => {
  const intl = useIntl()
  const hasTranslation = !!intl.messages[heading]

  const android = useMemo(() => stats.android || 0, [stats.android])
  const ios = useMemo(() => stats.ios || 0, [stats.ios])
  const web = useMemo(() => stats.web || 0, [stats.web])

  return (
    <KTCard>
      <KTCardBody>
        <CardTitle>{hasTranslation ? intl.$t({id: heading}) : heading}</CardTitle>

        <Chart
          options={{
            labels: ['Android', 'Ios', 'Web'],
            plotOptions: {
              pie: {
                expandOnClick: true,
                donut: {
                  labels: {
                    show: true,
                    total: {
                      label: 'Total',
                      show: true,
                    },
                  },
                },
              },
            },
            colors: [
              getCSSVariableValue('--bs-success'),
              getCSSVariableValue('--bs-secondary'),
              getCSSVariableValue('--bs-primary'),
            ],
          }}
          series={[android, ios, web]}
          type='donut'
          height='300'
        />
      </KTCardBody>
    </KTCard>
  )
}

type Props = {
  stats?: {
    web: {
      students: number
      parents: number
      staff: number
      teachers: number
    }
    android: {
      students: number
      parents: number
      staff: number
      teachers: number
    }
    ios: {
      students: number
      parents: number
      staff: number
      teachers: number
    }
  }
  userStats?: {
    students: number
    parents: number
    staff: number
    teachers: number
  }
  loading?: boolean
}

const StudentParentPlatform: FC<Props> = ({stats, userStats, loading}) => {
  if (loading || !stats || !userStats) {
    return (
      <KTCard>
        <KTCardBody>
          <div className='center p-5'>
            <Spinner color='primary' />
          </div>
        </KTCardBody>
      </KTCard>
    )
  }

  return (
    <div className='row mt-5'>
      <div className='col-md-6'>
        <StatsView
          heading='Connected Students devices platform wise'
          stats={{
            web: stats.web.students,
            android: stats.android.students,
            ios: stats.ios.students,
          }}
          total={userStats.students}
        />
      </div>
      <div className='col-md-6'>
        <StatsView
          heading='Connected Parents devices platform wise'
          stats={{
            web: stats.web.parents,
            android: stats.android.parents,
            ios: stats.ios.parents,
          }}
          total={userStats.students}
        />
      </div>
    </div>
  )
}

export default StudentParentPlatform
